function _fixTables() {
    /** Related Products and Categories **/
    $(".newContentsDiv").each(function() {
        var a = 0;
        $(this).find(".newContentsItemInfoInner .newContentsText").each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(this).find(".newContentsItemInfoInner .newContentsText").height(a);
        var a = 0;
        $(this).find(".newContentsItemInfoInner").each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(this).find(".newContentsItemInfoInner").height(a + 5);
        var a = 0;
        $(this).find(".newContentsItem").each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(this).find(".newContentsItem").height(a + 2)
    });
    var a = 0;
    // Not on personalized-gifts.html or trophies-baseball.html
    $(".topRatedSlider .item").each(function() {
        a = Math.max($(this).height(), a)
    }).height(a);

    // Items loop
 /*   for (var b = 0; b < $(".sections .item").length / 4; b++) {
        var c = $(".sections .item:eq(" + 4 * b + "),.sections .item:eq(" + (4 * b + 1) + "),.sections .item:eq(" + (4 * b + 2) + "),.sections .item:eq(" + (4 * b + 3) + ")"),
            a = 0;
        $(c).find(".itemMid").each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(c).find(".itemMid").height(a);
        var a = 0;
        $(c).find(".textBox").each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(c).find(".textBox").height(a)
    } */

    // sections loop
  /*  for (var b = 0; b < $(".sections .section").length / 3; b++) {
        var a = 0,
            d = $(".sections .section:eq(" + 3 * b + ") .textBlock,.sections .section:eq(" + (3 * b + 1) + ") .textBlock,.sections .section:eq(" + (3 * b + 2) + ") .textBlock");
        $(d).each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(d).height(a);
        var d = $(".sections .section:eq(" + 3 * b + "),.sections .section:eq(" + (3 * b + 1) + "),.sections .section:eq(" + (3 * b + 2) + ")");
        $(d).each(function() {
            $(this).height() > a && (a = $(this).height())
        }), $(d).height(a)
    } */

    // Each section.table-js-height
  $(".sections.table-js-height").each(function() {
        var this1 = $(this);

        if(this1.find(".itemMid").length > 0 && this1.find(".textBox").length > 0) {

            // console.log('items');

            // for each row
            // calculate row length based on window width and if sidebar is present
            // hardcoded for testing
            // var row_length = 5;
            if($(window).width() >= 1366) {
                // large desktop
                if($('#leftNavCell').length > 0) {
                    // left nav
                    var row_length = 4;
                } else {
                    var row_length = 5;
                }
            } else {
                // small desktop
                if($('#leftNavCell').length > 0) {
                    // left nav
                    var row_length = 3;
                } else {
                    var row_length = 4;
                }
            }

            var rows = [];

           var row_count = Math.ceil(this1.find('.item').length / row_length);
           // console.log('row_count: ' + row_count);
           for (var r = 1; r <= row_count; r++) {
                // console.log('item row!');

                // generate first and last boxes in row
                var first_box;
                var last_box;

                if(r === 1) {
                    // console.log('first row');
                    // first row
                    first_box = 1;
                    if(row_count === 1) {
                        last_box = this1.find('.item').length;
                    } else {
                        last_box = r * row_length;
                    }

                } else if(r === row_count) {
                    // console.log('last row');
                    // last row
                    first_box = ((r - 1) * row_length) + 1;
                    last_box = this1.find('.item').length;

                } else {
                    // console.log('in between row');
                    // in between rows
                    first_box = ((r - 1) * row_length) + 1;
                    last_box = r * row_length;
                }

                var boxes_arr = [];

                // populate boxes
                for(i=first_box; i <= last_box; i++) {
                    boxes_arr.push(i);
                }

                var row_obj = {
                    row: r,
                    boxes: boxes_arr
                }
                
                rows.push(row_obj);
            }

            // console.log(rows);

            rows.forEach(function(row) {

                if(row.row === 1) {
                    var nth_child_exp = ":nth-child(-n+" + row.boxes[row.boxes.length - 1] + ")";
                } else {
                    var nth_child_exp = ":nth-child(n+" + row.boxes[0] + "):nth-child(-n+" + row.boxes[row.boxes.length - 1] + ")";
                }

                // console.log("nth_child_exp: " + nth_child_exp);

                var itemMid_height = 0;
                this1.find(".item" + nth_child_exp + " .itemMid").each(function() {
                    $(this).height() > itemMid_height && (itemMid_height = $(this).height())
                }), this1.find(".item" + nth_child_exp + " .itemMid").height(itemMid_height);
                // console.log(itemMid_height);

                var textBox_height = 0;
                this1.find(".item" + nth_child_exp + " .textBox").each(function() {
                    $(this).height() > textBox_height && (textBox_height = $(this).height())
                }), this1.find(".item" + nth_child_exp + " .textBox").height(textBox_height);
                // console.log(textBox_height);

            })

    } else {

        // console.log('sections');

        // for each row
        // calculate row length based on window width and if sidebar is present
        // hardcoded for testing
        // var row_length = 5;
        if($(window).width() >= 1366) {
            // large desktop
            if($('#leftNavCell').length > 0) {
                // left nav
                var row_length = 3;
            } else {
                var row_length = 4;
            }
        } else {
            // small desktop
            if($('#leftNavCell').length > 0) {
                // left nav
                var row_length = 2;
            } else {
                var row_length = 3;
            }
        }

        var rows = [];

       var row_count = Math.ceil(this1.find('.section').length / row_length);
       // console.log('row_count: ' + row_count);
       for (var r = 1; r <= row_count; r++) {
            // console.log('item row!');

            // generate first and last boxes in row
            var first_box;
            var last_box;

            if(r === 1) {
                // console.log('first row');
                // first row
                first_box = 1;
                if(row_count === 1) {
                    last_box = this1.find('.section').length;
                } else {
                    last_box = r * row_length;
                }

            } else if(r === row_count) {
                // console.log('last row');
                // last row
                first_box = ((r - 1) * row_length) + 1;
                last_box = this1.find('.section').length;

            } else {
                // console.log('in between row');
                // in between rows
                first_box = ((r - 1) * row_length) + 1;
                last_box = r * row_length;
            }

            var boxes_arr = [];

            // populate boxes
            for(i=first_box; i <= last_box; i++) {
                boxes_arr.push(i);
            }

            var row_obj = {
                row: r,
                boxes: boxes_arr
            }
            
            rows.push(row_obj);
        }

        // console.log(rows);

        rows.forEach(function(row) {

            if(row.row === 1) {
                var nth_child_exp = ":nth-child(-n+" + row.boxes[row.boxes.length - 1] + ")";
            } else {
                var nth_child_exp = ":nth-child(n+" + row.boxes[0] + "):nth-child(-n+" + row.boxes[row.boxes.length - 1] + ")";
            }

            // console.log("nth_child_exp: " + nth_child_exp);

            var textBlock_height = 0;
            this1.find(".section" + nth_child_exp + " .textBlock").each(function() {
                $(this).height() > textBlock_height && (textBlock_height = $(this).height())
            }), this1.find(".section" + nth_child_exp + " .textBlock").height(textBlock_height);
            // console.log(textBlock_height);

            var section_height = 0;
            this1.find(".section" + nth_child_exp).each(function() {
                $(this).height() > section_height && (section_height = $(this).height())
            }), this1.find(".section" + nth_child_exp).height(section_height);
            // console.log(section_height);

        })

        /** start: sections **/
    /*    var textBlock_height = 0;
        this1.find(".textBlock").each(function() {
            $(this).height() > textBlock_height && (textBlock_height = $(this).height())
        }), this1.find(".textBlock").height(textBlock_height);
        console.log(textBlock_height);

        var section_height = 0;
        this1.find(".section").each(function() {
            $(this).height() > section_height && (section_height = $(this).height())
        }), this1.find(".section").height(section_height);
        console.log(section_height);  */

        /** end: sections **/

    }

    })

    // overrides - not on personalized-gifts.html or trophies-baseball.html
	$(".sections .item.colOverride .itemMid").css("height","auto");
    var a = 0;
	$(".sections .item.colOverride .itemMid").each(function() {
        a = Math.max($(this).height(), a)
    }).height(a);
	$(".sections .item.colOverride .textBox").css("height","auto");
    var a = 0;
	$(".sections .item.colOverride .textBox").each(function() {
        a = Math.max($(this).height(), a)
    }).height(a);
	$(".sections .item.colOverride").css("height","auto");
    var a = 0;
	$(".sections .item.colOverride").each(function() {
        a = Math.max($(this).height(), a)
    }).height(a);
}

function getBlogFeed() {
    var a = "http://site.trophycentral.com/blog1/api/get_recent_posts/",
        b = document.getElementById("feedContainer"),
        c = 3;
    if ($("#feedContainer:visible").length == 1) {      
      $.ajax({
          url: a,
          dataType: "jsonp",
          success: function(a) {
              var d = "";
              $.each(a.posts, function(a, b) {
                  a < c && (d += "<div class=post><a href='" + b.url + "'target=_blank''>" + b.title + "</a><div class=snip>" + b.excerpt.replace(/<.*?>/gi, "").substr(0, 100) + "...</div></div>")
              }), d += "", b.innerHTML = d
          }
      })
    }
}(!jQuery.browser.msie || parseInt(jQuery.browser.version) > 6) && ($("#toptabs table td:has(a)").mouseenter(function() {
    0 == $(this).parents(".nxt_refines_surround").length && ($("#toptabs div.nxtpTab").hide(), $(this).position().left >= .4 * $(window).width() && $(this).parents("table:first").next("div.nxtpTab").css("left", "auto").css("right", "0px"), $(this).parents("table:first").next("div.nxtpTab").show())
}), $("#tabsDiv").mouseleave(function() {
    $("#toptabs div.nxtpTab").hide()
})), jQuery.browser.safari && jQuery("#tc2015-header #ysw_search_box_id").css("line-height", "normal"), $(document).ready(function() {
    try {
        var a = $("#freeShippingCell").position().left,
            b = $("#freeShippingCell").position().top + 3;
        for ($("#freeShippingLink").css("cssText", "position: absolute;top: " + b + "px;left: " + a + "px;"), i = 0; i < menuLen; i++) $("#toptabs .tabcell" + i).mouseenter(function() {
            var a = $("#tabsDiv").offset().left,
                b = $(this).offset().left,
                c = b - a;
            $(this).is(":last-child") ? $(".topMenuSub", this).css("cssText", "right:0px !important;position: absolute;top:49px;") : $(".topMenuSub", this).css("cssText", "position:absolute;left:" + c + "px;top:49px;"), $("#toptabs.newTopNav .topMenuSub").css("top", "40px"), $(".topMenuSub", this).show()
        }).mouseleave(function() {
            $(".topMenuSub", this).hide(), $(".topMenuSub:last").hide()
        }), $("#toptabs .tabcellSelect" + i).mouseenter(function() {
            var a = $("#tabsDiv").offset().left,
                b = $(this).offset().left,
                c = b - a;
            $(this).is(":last-child") ? $(".topMenuSub", this).css("cssText", "right:0px !important;position: absolute;top:49px;") : $(".topMenuSub", this).css("cssText", "position:absolute;left:" + c + "px;top:49px;"), $("#toptabs.newTopNav .topMenuSub").css("top", "40px"), $(".topMenuSub", this).show()
        }).mouseleave(function() {
            $(".topMenuSub", this).hide(), $(".topMenuSub:last").hide()
        }), $("#toptabs.newTopMenu .tabcell" + i).mouseenter(function() {
            $(this).css("background-color", "#001f77"), $(this).css("border-right-color", "#001f77"), $("a", this).css("color", "white")
        }).mouseleave(function() {
            $(this).css("background-color", "white"), $(this).css("border-right-color", "#336699"), $("a", this).css("color", "#336699")
        });
        $(".topMenuSub").each(function() {
            var a = $(this).prev().find("a").clone();
            a.length > 0 && ($(a).css("font-weight", "bold"), $(a).text("All " + $(a).text() + " Sections"), $(this).prepend(a))
        })
    } catch (a) {}
    getBlogFeed(), $("#shopByDropDown").click(function() {
        return $("#shopByChoices").toggle(), !1
    }), $("#quantityPricing").prepend("<h2>Quantity Pricing Chart</h2>"), 0 != $("#quantityPricing table").length && $("#quantityPricingButton").show(), $(".fancybox").fancybox(), $(".slider").each(function() {
        var a;
        $(this);
        a = $(this).hasClass("heroSlider") ? {
            arrows: !0,
            autoplay: !0,
            autoplaySpeed: 7e3,
            dots: !0
        } : $(this).hasClass("topRatedSlider") ? {
            arrows: !0,
            slidesToShow: 4,
            autoplay: !1,
            dots: !1
        } : {
            arrows: !0,
            autoplay: !1,
            dots: !1
        }, $(this).slick(a)
    })
}), $(window).load(function() {
    _fixTables()
});